<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div.text-uppercase {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`) }}
      span(v-if="checkAccess('backOffice')") (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    NewAccountsInfo(v-if="sailorDocument?.behavior?.viewInfoBlock" :sailorDocument="sailorDocument")
    NewAccountsEdit(v-else-if="sailorDocument.behavior?.viewEditBlock" :getDocuments="getData" :sailorDocument="sailorDocument")
    RejectNewAccount(v-else-if="sailorDocument.behavior.viewRejectBlock")
    ViewPhotoList(
      v-else-if="sailorDocument?.behavior?.viewFilesBlock"
      :sailorDocument="sailorDocument"
      :documentType="type")
</template>

<script>
import { viewDetailedComponent, back } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState } from 'vuex'
import { approveNewAccount, rejectNewAccount } from '@/configs/constants'
export default {
  name: 'NewAccountsDocument',
  components: {
    NewAccountsInfo: () => import('@/views/NewAccounts/NewAccountsInfo.vue'),
    NewAccountsEdit: () => import('@/views/NewAccounts/NewAccountsEdit.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    RejectNewAccount: () => import('./Reject.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'newAccounts',
      viewDetailedComponent,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-reactivate',
          action: () => {
            window.open(`${location.origin}/sailor/${this.sailorDocument.sailor_id}/passports/sailors`, '_blank')
            // return this.$router.push({ name: 'passports-sailors', params: { id: this.sailorDocument.sailor_id } })
          },
          checkAccess: () => this.sailorDocument.sailor_id,
          color: '#42627e',
          tooltip: 'documentIconControl.goToSailor',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        // {
        //   id: 2,
        //   name: 'mdi-plus-outline',
        //   action: () => viewDetailedComponent(this.sailorDocument, 'viewAddBlock'),
        //   checkAccess: () => this.sailorDocument.status_document.id === verificationNewAccount && !this.sailorDocument.sailor_id,
        //   color: 'green',
        //  tooltip: 'documentIconControl.createSailor'),
        //   buttonOptions: {
        //     xSmall: false,
        //     fab: true,
        //     icon: true,
        //     color: 'green',
        //     outlined: false
        //   }
        // },
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        {
          id: 4,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => ![approveNewAccount, rejectNewAccount].includes(this.sailorDocument.status_document.id) && checkAccess('newAccounts', 'edit'),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        {
          id: 5,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('newAccounts', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        },
        {
          id: 6,
          name: 'mdi-account-remove-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewRejectBlock'),
          checkAccess: () => ![approveNewAccount, rejectNewAccount].includes(this.sailorDocument.status_document.id) && checkAccess('newAccounts', 'reject'),
          color: 'red',
          tooltip: 'documentIconControl.rejectStatement',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: 'red',
            outlined: false
          }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => back('new-accounts'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: {
            xSmall: false,
            fab: true,
            icon: true,
            color: '#42627e',
            outlined: false
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      id: state => state.sailor.sailorId,
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      sailorDocument: state => state.sailor.newAccoutById
    }),
    documentID () {
      return this.$route.params.documentID
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getNewAccountsById']),
    async getData () {
      await this.getNewAccountsById({ ...this.$route.params })
    }
  }
}
</script>
